footer {
  color: grey;
  font-size: 12px;
  text-align: center;
}

footer .footer-link {
  color: #3a86ff;
  text-decoration: none;
  transition: all 200ms ease-in-out;
}

footer .footer-link:hover {
  color: #f96b0b;
  text-decoration: underline;
  cursor: pointer;
}
