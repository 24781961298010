.artdesign-intro {
  font-family: var(--body-font);
  font-weight: 400;
  background-color: var(--primary-color);
  border: solid 4px var(--secondary-color);
  box-shadow: 15px 15px 0 -4px var(--secondary-color),
    15px 15px var(--secondary-color);
  margin: 0 auto;
  width: 50%;
}

.design-row {
  font-family: var(--body-font);
}

.design-row h4 {
  font-weight: 500;
}

.design-row small {
  color: grey;
  font-size: 12px;
  font-weight: 400;
}

.wrapper {
  overflow: hidden;
}

.wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms;
}

.wrapper:hover img {
  transform: scale(1.2);
}

.project-main {
  display: block;
  margin: 0 auto;
  max-width: 90%;
}

.project-main-image {
  display: block;
  margin: 0 auto;
}

.project-title {
  color: var(--secondary-color);
  font-family: var(--body-font);
  font-size: 50px;
  font-weight: 700;
  text-align: left;
}

.project-tag {
  color: grey;
  font-family: var(--body-font);
  font-size: 20px;
  font-weight: 300;
}

.project-description {
  color: var(--secondary-color);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 200;
}

.portfolio-link {
  color: var(--secondary-color);
  font-style: italic;
  text-decoration: none;
  transition: all 200ms ease-in-out;
}

.portfolio-link:hover {
  color: var(--artdesign-color);
}

.project-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-family: var(--title-font);
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  padding: 12px 18px;
  border: solid 4px var(--secondary-color);
  box-shadow: 4px var(--secondary-color);
  transition: transform 50ms, box-shadow 50ms;
}

.project-button:hover {
  background-color: var(--artdesign-color);
  color: var(--primary-color);
  transform: translate(4px, 4px);
  box-shadow: 4px 4px var(--secondary-color);
}

.back-to-projects {
  display: block;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--body-font);
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 500;
  transition: all 200ms ease-in-out;
}

.back-to-projects:hover {
  color: var(--artdesign-color);
}

@media (min-width: 0) and (max-width: 576px) {
  .page-banner {
    display: none;
  }
  .artdesign-intro {
    font-size: 12px;
    width: fit-content;
  }

  .design-row h4 {
    font-size: 20px;
  }

  .project-title {
    font-size: 32px;
  }

  .project-tag {
    font-size: 16px;
  }

  .project-description {
    font-size: 12px;
  }

  .project-button {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .artdesign-intro {
    font-size: 14px;
    padding: 0 20px;
    width: fit-content;
  }

  .design-row h4 {
    font-size: 22px;
  }
}
