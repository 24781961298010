h1 {
  font-family: var(--title-font);
  font-weight: 900;
}

.music-intro {
  font-family: var(--body-font);
  font-weight: 400;
  background-color: var(--primary-color);
  border: solid 4px var(--secondary-color);
  box-shadow: 15px 15px 0 -4px var(--secondary-color),
    15px 15px var(--secondary-color);
  margin: 0 auto;
  width: 60%;
}

@media (min-width: 0) and (max-width: 576px) {
  .music-intro {
    font-size: 12px;
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .music-intro {
    padding: 0 10px;
    width: 90%;
  }
}
