h1 {
  font-family: var(--title-font);
  font-weight: 900;
}

.intro-card {
  background-color: var(--primary-color);
  border: solid 4px var(--secondary-color);
  box-shadow: 15px 15px 0 -4px var(--secondary-color),
    15px 15px var(--secondary-color);
}

.intro-card h1,
h3 {
  font-family: var(--title-font);
}

.intro-card h1 {
  font-weight: 900;
}

.intro-card h3,
.skills h4 {
  font-weight: 700;
}

.intro-card .about-paragraph,
.skills .skill-list,
.experience-info {
  font-family: var(--body-font);
  font-weight: 400;
}

.click-link {
  color: var(--secondary-color);
  text-decoration: none;
  transition: all 200ms ease-in-out;
}

.click-link:hover {
  color: var(--about-color);
  text-decoration: underline;
  cursor: pointer;
}

.experience-title {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 500;
}

.experience-duration {
  color: grey;
  font-style: italic;
  font-size: small;
}

#head-shot {
  display: block;
  margin: 0 auto;
  max-width: 330px;
  max-height: 330px;
  border-radius: 330px;
}

@media (min-width: 0) and (max-width: 576px) {
  h1 {
    font-size: 32px;
  }

  h3 {
    font-size: 16px;
  }

  .skills h4 {
    font-size: 14px;
  }

  p,
  .experience-title,
  .experience-duration {
    font-size: 12px;
  }

  .intro-card {
    width: fit-content;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .intro-card {
    width: fit-content;
  }
}
