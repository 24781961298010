.home-hero {
  text-align: center;
}

.home-hero h1,
h2 {
  color: var(--secondary-color);
  font-family: var(--title-font);
  font-weight: 900;
  text-transform: uppercase;
  line-height: 0.9;
}

.home-hero h1 {
  font-size: 120px;
}

.home-hero h2 {
  font-size: 77px;
}

.home-hero h3 {
  color: grey;
  font-family: var(--body-font);
  font-weight: 400;
}

.home-featured {
  text-align: center;
}

.home-featured h1 {
  color: var(--secondary-color);
  font-family: var(--title-font);
  font-weight: 900;
  text-transform: uppercase;
}

.home-featured h4 {
  font-family: var(--body-font);
  font-weight: 500;
}

.home-featured small {
  color: grey;
  font-family: var(--body-font);
  font-size: 12px;
  font-weight: 400;
}

.navigation-links {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  font-family: var(--body-font);
  font-weight: 500;
}

.wrapper {
  overflow: hidden;
}

.wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms;
}

.wrapper:hover img {
  transform: scale(1.2);
}

#about-link,
#work-link,
#music-link {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-family: var(--title-font);
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  margin: 10px;
  padding: 12px 18px;
  border: solid 4px var(--secondary-color);
  box-shadow: 4px var(--secondary-color);
  transition: transform 50ms, box-shadow 50ms;
}

#about-link:hover {
  background-color: var(--about-color);
  color: var(--primary-color);
  transform: translate(4px, 4px);
  box-shadow: 4px 4px var(--secondary-color);
}

#work-link:hover {
  background-color: var(--artdesign-color);
  color: var(--primary-color);
  transform: translate(4px, 4px);
  box-shadow: 4px 4px var(--secondary-color);
}

#music-link:hover {
  background-color: var(--music-color);
  color: var(--primary-color);
  transform: translate(4px, 4px);
  box-shadow: 4px 4px var(--secondary-color);
}

@media (min-width: 0) and (max-width: 576px) {
  .home-hero h1,
  h2 {
    line-height: 0.9;
  }

  .home-hero h1 {
    font-size: 60px;
  }

  .home-hero h2 {
    font-size: 35px;
  }

  .home-hero h3 {
    font-size: 16px;
  }

  .navigation-links {
    font-size: 10px;
  }
}
