:root {
  --primary-color: #fff;
  --secondary-color: #121212;
  --about-color: #3a86ff;
  --artdesign-color: #f96b0b;
  --music-color: #8338ec;
  --title-font: "Outfit", sans-serif;
  --body-font: "IBM Plex Sans", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary-color);
}

img {
  pointer-events: none;
}

.navbar-brand {
  margin-left: 20px;
}

.menu-tabs {
  margin-right: 20px;
}

#site-logo {
  max-width: 100px;
}

#nav-about:hover {
  color: var(--about-color);
}

#nav-artdesign:hover {
  color: var(--artdesign-color);
}

#nav-music:hover {
  color: var(--music-color);
}
